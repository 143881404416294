import { useFormikContext } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";
import _ from "lodash";
import { VehicleRentalApi } from "../api";
import { defaultAPIConfig } from "../config/defaultApiConfig";
import { GreyContainer } from "../layouts/model";
import CarDetail from "./CarDetail";
import { useGetCustomerAccount } from "../hooks/useGetCustomerAccount";
import CallToAction, { StyledButton } from "./CallToAction";

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 11;
  justify-content: center;
  padding-top: 20px;
  min-height: 400px;
`;

const AlternativeTitle = styled.h1`
  width: 100%;
  padding: 40px 80px;
  margin: 0;
`;
const NoResults = styled.div`
  padding: 40px;
  text-align: center;
  background-color: lightskyblue;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const api = new VehicleRentalApi(defaultAPIConfig());

const FilterResultsList: React.FC = () => {
  const { user } = useGetCustomerAccount();
  const [vehicles, setVehicles] = useState({});
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const [equipment, setEquipment] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { values } = useFormikContext();

  // get all Infos out of filter for request and booking page
  const start = (values.start && new Date(values.start)) || undefined;
  const end = (values.end && new Date(values.end)) || undefined;
  const pickupLocationId = values.pickupLocation && values.pickupLocation.value;
  const returnLocationId = values.returnLocation && values.returnLocation.value;
  const vehicleCategoryId =
    values.vehicleCategory && values.vehicleCategory.value;

  const payload = {
    start,
    end,
    pickupLocationId,
    returnLocationId,
    vehicleCategoryId,
  };

  useEffect(() => {
    if (payload.end > payload.start) {
      setIsLoading(true);
      const makeBooking = async () => {
        try {
          const bookingResponse = await api.bookingrequestGet(payload, {
            headers: {
              accept: "application/vnd.api+json",
              "content-type": "application/vnd.api+json",
            },
          });

          if (bookingResponse.data) {
            const groupedData = _.groupBy(
              bookingResponse.data,
              (x) => x.attributes.pickupLocation?.id
            );

            const sortedData = _.orderBy(
              groupedData,
              (models) => models?.[0]?.attributes?.pickupLocation?.distance,
              "asc"
            );
            setVehicles(sortedData);
          }
        } catch (error) {
          setVehicles({});
        }
        setIsLoading(false);
        setErrors([]);
        setInitiallyLoaded(true);
      };

      makeBooking();
    } else {
      const err: any = [];
      err.push({ text: "Die Rückgabe ist vor der Abholung." });
      setVehicles({});
      setErrors(err);
    }
  }, [values]);

  useEffect(() => {
    setIsLoading(true);
    const getEquipment = async () => {
      const getEquipmentResponse = await api.equipmentGet({
        headers: {
          accept: "application/vnd.api+json",
          "content-type": "application/vnd.api+json",
        },
      });

      if (getEquipmentResponse.data) {
        setEquipment(getEquipmentResponse.data);
      }
      setIsLoading(false);
    };

    getEquipment();
  }, []);

  // Get list for all equipment possibilities for all cars in the list for the given pickup location

  const findEquipmentListForLocation = (locationId: any) => {
    const list = equipment.filter((equipmentItem: any) => {
      const rentalLocations =
        equipmentItem.relationships.rentallocations.data.find(
          (rentallocationItem: any) => +rentallocationItem.id === +locationId
        );
      if (rentalLocations) {
        return true;
      }
    });
    return list;
  };
  return (
    <GreyContainer>
      <div
        style={{ minHeight: "200px", display: isLoading ? "flex" : "none" }}
      />
      <LoaderWrapper style={{ display: isLoading ? "flex" : "none" }}>
        <ClipLoader
          color={"#03498a"}
          loading={isLoading}
          size={150}
          aria-label="Laden"
          data-testid="loader"
        />
      </LoaderWrapper>
      {errors.length > 0 &&
        !isLoading &&
        errors.map((error: any) => <NoResults>{error.text}</NoResults>)}

      {initiallyLoaded &&
        !isLoading &&
        !_.isEmpty(vehicles) &&
        vehicles[0][0].attributes.pickupLocation.id !== pickupLocationId && (
          <NoResults>
            Leider können wir Ihnen mit den ausgesuchten Kriterien am gewählten
            Standort kein Fahrzeug anbieten. Alternative Standorte wären jedoch
            verfügbar.
          </NoResults>
        )}

      {initiallyLoaded && !isLoading && _.isEmpty(vehicles) && (
        <NoResults>
          Leider können wir Ihnen aktuell mit den ausgesuchten Kriterien kein
          Mietfahrzeug anbieten.
          <br />
          Bitte passen Sie Ihre Suchkriterien an oder nehmen Sie mit uns Kontakt
          auf damit wir eine für Sie passende Mobilitätslösung finden können.
          <br />
          <StyledButton href="tel:+41712801010">Rufen Sie uns an</StyledButton>
          <br />
          <br />
          <StyledButton href="mailto:info@rentir.ch">
            Lösung per Mail finden
          </StyledButton>
        </NoResults>
      )}

      {!_.isEmpty(vehicles) &&
        vehicles[0][0].attributes.pickupLocation.id === pickupLocationId &&
        vehicles[0].map((vehicle: any) => (
          <CarDetail
            key={vehicle.id}
            model={vehicle}
            filterPayload={payload}
            possibleEquipment={findEquipmentListForLocation(pickupLocationId)}
            pickupLocationId={pickupLocationId}
            user={user}
          />
        ))}

      {Object.keys(vehicles).map((key, index) => {
        if (vehicles[0][0].attributes.pickupLocation.id === pickupLocationId) {
          return null;
        }
        return (
          <>
            <AlternativeTitle>
              Alternativer Abholstandort{" "}
              {vehicles[key][0].attributes.pickupLocation.location}
            </AlternativeTitle>
            {vehicles[key].map((vehicle: any) => (
              <CarDetail
                key={vehicle.id}
                model={vehicle}
                filterPayload={payload}
                possibleEquipment={findEquipmentListForLocation(
                  vehicle.attributes.pickupLocation.id
                )}
                pickupLocationId={vehicle.attributes.pickupLocation.id}
                user={user}
              />
            ))}
          </>
        );
      })}
    </GreyContainer>
  );
};

export default FilterResultsList;
