import * as React from "react";
import DefaultLayout from "./default";
import Container from "../components/Container";
import { BlueBar, TitleContainer } from "./model";
import CarSearch from "../components/CarSearch";

interface BookingProps {
  pageContext: {
    locations: any;
    vehicleCategories: any;
  };
}

const Booking: React.FC<BookingProps> = (props) => {
  const {
    pageContext: { locations, vehicleCategories },
  } = props;

  return (
    <DefaultLayout>
      <BlueBar>
        <Container>
          <TitleContainer>Buchbare Fahrzeuge</TitleContainer>
        </Container>
      </BlueBar>
      <CarSearch locations={locations} vehicleCategories={vehicleCategories} />
    </DefaultLayout>
  );
};

export default Booking;
