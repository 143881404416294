import * as React from "react";
import styled from "styled-components";
import { Form, Formik } from "formik";
import { breakpoints, colors, dimensions } from "../styles/variables";
import DropdownInput from "./DropdownInput";
import DateInput from "./DateInput";
import TimeInput from "./TimeInput";
import FilterResultsList from "./FilterResultsList";

import dayjs from "dayjs";
import { roundTime } from "./CarSearchMinimal";

const Flex = styled.div`
  @media (min-width: ${breakpoints.md}px) {
    display: flex;
  }
`;
const WidthLimiter = styled.div`
  @media (min-width: ${breakpoints.md}px) {
    margin: 0 auto;
    max-width: 1006px;
  }
`;

const FlexElement = styled.div`
  padding: 0 4px;
  flex: 1 1 0px;
`;

const StyledCarSearch = styled.div`
  background-color: ${colors.brandBlue};
  padding: 36px;
  h2 {
    text-transform: uppercase;
    font-size: ${dimensions.fontSize.medium}px;
    line-height: ${dimensions.lineHeight.medium}px;
    font-weight: ${dimensions.fontWeight.light};
    color: ${colors.white};
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 50px;

    @media (min-width: ${breakpoints.md}px) {
      display: none;
    }
  }
`;

interface CarSearchProps {
  locations: any;
  vehicleCategories: any;
}

const CarSearch: React.FC<CarSearchProps> = (props) => {
  // All Data which can be prepared at build time
  const { locations, vehicleCategories } = props;

  const vehicleCategoryOptions = vehicleCategories.data.map(
    (category: any) => ({
      value: category.id,
      label: category.attributes.title,
    })
  );
  const locationOptions = locations.data.map((location: any) => ({
    value: location.id,
    label: location.attributes.name,
  }));

  const params =
    typeof window !== "undefined"
      ? new URLSearchParams(window.location.search.substr(1))
      : new URLSearchParams("");
  const start =
    params.get("start") !== null
      ? dayjs(params.get("start"))
      : roundTime(dayjs().add(1, "hour"), 15);
  const end =
    params.get("end") !== null
      ? dayjs(params.get("end"))
      : dayjs().set("hour", 17).set("minute", 0).set("second", 0);
  const pickupLocationId =
    params.get("pickupLocationId") !== null
      ? +params.get("pickupLocationId")
      : undefined;

  const returnLocationId =
    params.get("returnLocationId") !== null
      ? +params.get("returnLocationId")
      : undefined;

  const vehicleCategoryId =
    params.get("vehicleCategoryId") !== null
      ? +params.get("vehicleCategoryId")
      : undefined;

  // Get type with id
  const vehicleCategoryObject = vehicleCategories.data.find(
    (vehicleCategory: any) => vehicleCategory.id === String(vehicleCategoryId)
  );
  const vehicleCategory = {
    label:
      (vehicleCategoryObject && vehicleCategoryObject.attributes.title) ||
      undefined,
    value: (vehicleCategoryObject && vehicleCategoryObject.id) || undefined,
  };

  // Get pickuplocation with id
  const pickupLocationObject = locations.data.find(
    (location: any) => location.id === String(pickupLocationId)
  );
  const pickupLocation = {
    label:
      (pickupLocationObject && pickupLocationObject.attributes.name) ||
      undefined,
    value: (pickupLocationObject && pickupLocationObject.id) || undefined,
  };

  // Get returnlocation with id
  const returnLocationObject = locations.data.find(
    (location: any) => location.id === String(returnLocationId)
  );
  const returnLocation = {
    label:
      (returnLocationObject && returnLocationObject.attributes.name) ||
      undefined,
    value: (returnLocationObject && returnLocationObject.id) || undefined,
  };

  const searchFilterParams = {
    start,
    end,
    pickupLocation,
    returnLocation,
    vehicleCategory,
  };

  return (
    <>
      <Formik
        initialValues={searchFilterParams}
        onSubmit={(values: any) => {
          // no submit
        }}
      >
        <>
          <StyledCarSearch>
            <h2>Fahrzeugmiete die rentiert</h2>

            <Form>
              <WidthLimiter>
                <Flex>
                  <FlexElement>
                    <DropdownInput
                      name="vehicleCategory"
                      title="Fahrzeugkategorie"
                      options={vehicleCategoryOptions}
                    />
                  </FlexElement>
                  <FlexElement>
                    <DropdownInput
                      name="pickupLocation"
                      title="Anmietstation"
                      options={locationOptions}
                    />
                  </FlexElement>
                  <FlexElement>
                    <DropdownInput
                      name="returnLocation"
                      title="Rückgabestation"
                      options={locationOptions}
                    />
                  </FlexElement>
                </Flex>
                <Flex>
                  <FlexElement>
                    <DateInput
                      title="Abholdatum"
                      name="start"
                      timeHoursAfterChange={8}
                    />
                  </FlexElement>
                  <FlexElement>
                    <TimeInput title="Abholzeit" name="start" />
                  </FlexElement>
                  <FlexElement>
                    <DateInput title="Rückgabedatum" name="end" />
                  </FlexElement>
                  <FlexElement>
                    <TimeInput title="Rückgabezeit" name="end" />
                  </FlexElement>
                </Flex>
              </WidthLimiter>
            </Form>
          </StyledCarSearch>
          <FilterResultsList />
        </>
      </Formik>
    </>
  );
};

export default CarSearch;
